import React from "react"
import Layout from "../components/Layout/Layout"

import { graphql, useStaticQuery, Link } from "gatsby"
import Img from "gatsby-image"
import HomeLayout from "../components/HomeLayout/HomeLayout"

const Home = () => {
  const data = useStaticQuery(graphql`
    query {
      Home: allFile(
        sort: { order: ASC, fields: name }
        filter: { sourceInstanceName: { eq: "Home" } }
      ) {
        nodes {
          id
          name
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `)

  
  return (
    <Layout>
      <HomeLayout>
        {data.Home.nodes.map(img => {
          var name = img.name.split(" ")
          return (
            <Link to={`/${name[1]}`}>
              <Img
                fluid={img.childImageSharp.fluid}
                alt={name[1]}
                key={img.id}
              />
            </Link>
          )
        })}
      </HomeLayout>
    </Layout>
  )
}

export default Home
